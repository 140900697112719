import React, { Component } from 'react';
import { Drawer, Menu, Icon, Button, Input, notification} from 'antd';
import { Link, Redirect } from 'react-router-dom';
import {requestHttp} from '../Services/request.service';
import {
    Navbar,
    Nav,
    Col,
    Row,
    Collapse, NavItem
} from 'reactstrap';

export default class AdminContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            isOpen: false,
            redirect: false,
            default: NaN,
            username: "",
            responsive:false
        };
        this.VerificarRut = this.VerificarRut.bind(this);
        this.onSearchRut = this.onSearchRut.bind(this);
    }

    showChat () {
	    this.finishChatLoad()
    }

    chatOnLoad() {
	    setTimeout(() => this.finishChatLoad(), 1000)
    }

    finishChatLoad () {
        const styles = `
            div.cx-widget.cx-window-manager.cx-theme-light {
                display: block !important;
            }`
        
        const tag = document.createElement('style')
        tag.id = 'show-chat'
        tag.innerHTML = styles
        document.head.appendChild(tag)
    }

    hideChat () {
        document.getElementById('show-chat').remove()
    }

    async componentWillMount() {
        this.showChat()


        let pathname = this.props.location.pathname;
        if(pathname != null){
            this.setState({
                test: pathname
            });
        }
    }

    async componentWillReceiveProps(next){

        let pathname = this.props.location.pathname;
        if(pathname != null){
            this.setState({
                test:pathname
            });
        }
    }
    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    async salir() {
        this.hideChat()
        sessionStorage.clear()
        localStorage.clear()
        document.getElementById('frameChatWolkvox').remove()
    }
    Clicked(e){
        console.log(e)
    }

    toggle() {
        this.setState({
            collapsed: true
        });
    }

    isPending() {
        return window.location.pathname.includes('pending')
    }

    renderEvents() {
        return this.state.all_events.map((item, index) => {
            return <option key={item.id + index} value={item.id}> {item.name} </option>
        })
    }

    VerificarRut = (Objeto) => {
        var tmpstr = "";
        var intlargo = Objeto
        if(intlargo  ===  undefined || intlargo === ""){
            return false
        }
        if (intlargo.length > 0 )
        {
            var crut = Objeto
            var largo = crut.length;
            if ( largo <2 )
            {
                return false;
            }
            for (var i=0; i <crut.length ; i++ )
            if ( crut.charAt(i) != ' ' && crut.charAt(i) != '.' && crut.charAt(i) != '-' )
            {
                tmpstr = tmpstr + crut.charAt(i);
            }
            var rut = tmpstr;
            crut=tmpstr;
            largo = crut.length;
            
            if ( largo> 2 )
            rut = crut.substring(0, largo - 1);
            else
            rut = crut.charAt(0);
            
            var dv = crut.charAt(largo-1);
            
            if ( rut == null || dv == null )
            return 0;
            
            var dvr = '0';
            var suma = 0;
            var mul  = 2;
            
            for (var i= rut.length-1 ; i>= 0; i--)
            {
                suma = suma + rut.charAt(i) * mul;
                if (mul == 7)
                mul = 2;
                else
                mul++;
            }
            var res = suma % 11;
            if (res==1)
            var dvr = 'k';
            else if (res==0)
            dvr = '0';
            else
            {
                var dvi = 11-res;
                dvr = dvi + "";
            }
            
            if ( dvr != dv.toLowerCase() )
            {
                return false;
            }
            return true;
        }
    }

    async onSearchRut (rutBuscado) {/* motachi */
    
        localStorage.removeItem("rutBuscado")
        sessionStorage.removeItem('rutBuscadovalidateClient')
        sessionStorage.removeItem('user')
        if(this.VerificarRut(rutBuscado)){
            try{
            //console.log("valido"+ rutBuscado);
            localStorage.setItem("rutBuscado",rutBuscado)
            rutBuscado = rutBuscado.split(".").join("");
            let validateClient = await requestHttp.validateCustomer(rutBuscado)
            sessionStorage.setItem('rutBuscadovalidateClient', JSON.stringify(validateClient))

            let user  = await requestHttp.getLoginData(rutBuscado)
            sessionStorage.setItem('user', JSON.stringify(user))
            window.location.reload()
            }catch(ex){
                notification.open({
                    message: 'Rut no encontrado',
                    description:
                    'Comuniquese con TI',
                });
                console.log(ex);
                localStorage.removeItem("rutBuscado")
                sessionStorage.removeItem('rutBuscadovalidateClient')
                sessionStorage.removeItem('user')
            }

        }else{
            notification.open({ message: 'Rut no valido', description: rutBuscado });
        }
    }

    

    render() {
        let children = this.props.children

        const postDueAvailable = localStorage.getItem('postDueAvailable') === '1'

        let MainLayout =
            <div className="container-fluid">
                {
                    this.state.responsive
                    ? <Drawer
                        placement={'left'}
                        closable={false}
                        onClose={this.toggleCollapsed}
                        visible={this.state.collapsed}
                        key={'left'}
                    >
                        <Menu
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            selectedKeys={[this.state.test]}
                        >
                            <Menu.Item key="/admin/causes" onClick={ this.state.responsive ? ()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                <Link to="/admin/causes">
                                    <span>
                                        Mis Movimientos
                                    </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/profile">
                                <Link to="/admin/profile" onClick={ this.state.responsive ?()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                    <span>
                                        Mis Datos
                                    </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/pending">
                                <Link to="/admin/pending" onClick={ this.state.responsive ?()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                    <span>
                                        Mi Cuenta Corriente
                                    </span>
                                </Link>
                            </Menu.Item>
                            {this.state.responsive ? 
                                <Menu.Item key="5">
                                    <Link to="/" className="logout-btn"  style={{lineHeight: "46px"}} onClick={(e)=> { this.toggleCollapsed(); this.salir(e) } } > <img src={require("../../assets/images/logout.png")} /> <span>Salir</span> </Link>
                                </Menu.Item>
                            : null}
                        </Menu>
                    </Drawer>
                  : null
                }

                <Row>
                    <Col xl="12" >
                        <div className="nav-top">
                            <Navbar color="light" light expand="md">
                                <div className="logo-nav"><img src={require("../../assets/images/logo.png")} className="logo-admin" /></div>

                                <div className='w-25'>
                                    <Input.Search placeholder="Ingrese el RUT" onSearch={ this.onSearchRut } enterButton="Buscar" size="large" allowClear/>
                                </div>   

                                <Nav className="ml-auto align-items-center" navbar>
                                    <h6 style={{margin: "0px 20px 0px 0px", lineHeight: "46px"}}>{this.state.username}</h6>
                                    <Collapse navbar>
                                        <Nav navbar className="navbar-right">
                                            <NavItem>
                                                <Link to="/" className="logout-btn" onClick={(e)=> this.salir(e) } > <img src={require("../../assets/images/logout.png")} /> <span>Salir</span> </Link>
                                            </NavItem>
                                        </Nav>
                                    </Collapse>
                                    {this.state.responsive ?  <Button type="white" className="toggle-mobile" onClick={this.toggleCollapsed}>
                                        <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                                    </Button> : null}
                                </Nav>
                            </Navbar>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        this.state.responsive === false
                        ? <Col xl="2" lg="2" md="2" sm="3" xs="12" style={{paddingLeft:"0px", paddingRight: "0px"}}>
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    breakpoint="lg"
                                    collapsedWidth="0"
                                    defaultOpenKeys={['sub1']}
                                    selectedKeys={[this.state.test]}
                                    mode="inline"
                                    inlineCollapsed={this.state.collapsed}>
                                    <div className="header-admin">
                                        
                                        {/*<Button type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16 }}>
                                                <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                                            </Button>*/}
                                    </div>
                                    <Menu.Item key="/admin/causes" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                        <Link to="/admin/causes">
                                            <span>
                                                Mis Movimientos
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/profile">
                                        <Link to="/admin/profile" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                            <span>
                                                Mis Datos
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/pending">
                                        <Link to="/admin/pending" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                            <span>
                                                Mi Cuenta Corriente
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    {this.state.responsive ? 
                                        <Menu.Item key="5">
                                            <Link to="/" className="logout-btn"  style={{lineHeight: "46px"}} onClick={(e)=> this.salir(e) } > <img src={require("../../assets/images/logout.png")} /> <span>Salir</span> </Link>
                                        </Menu.Item>
                                    : null}
                                </Menu>
                            
                        </Col>
                        : null
                    }
                    
                    <Col xl="10" lg="10" md="10" sm="9" xs="12" style={{ paddingBottom: '6em' }}>
                        {children}
                        {this.state.redirect ? 
                        <Redirect to="/" /> : null}
                    </Col>
                </Row>
            </div>
        return MainLayout
    }
}
