import React, { Component } from 'react';
import {Row, Container, Col, FormGroup, Input, Form} from 'reactstrap';
import { Link } from 'react-router-dom';
import {requestHttp} from '../Services/request.service';
import { notification } from 'antd';
import Loader from '../Loader';
import { caseToZoho } from '../Services/client_data'
var CryptoJS = require("crypto-js")


class ForgetPassword extends Component{

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      rut:'',
      password:'',
      oldPassword: '',
      loader: false
    };
  }
  componentDidMount(){
    this.setState({
        collapsed: false,
        rut: '',
        password:''
    })
}

formateaRut(rut) {
    var actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (var i = inicio.length - 1; i >= 0; i--) {
            var letra = inicio.charAt(i);
            rutPuntos = letra + rutPuntos;
            if (j % 3 == 0 && j <= inicio.length - 1) {
                rutPuntos = "." + rutPuntos;
            }
            j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
}

async handleChange(e){
    if(e.name === "rut"){
        let format = this.formateaRut(e.value)
        await this.setState({
            rut: format
        });
    }
    else{
        await this.setState({
            [e.name] : e.value
        })
    }
}


async validarRut( Objeto ){
    var tmpstr = "";
    var intlargo = Objeto
    if(intlargo  ===  undefined || intlargo === ""){
        return false
    }
    if (intlargo.length > 0 )
    {
        var crut = Objeto
        var largo = crut.length;
        if ( largo <2 )
        {
            return false;
        }
        for (var i=0; i <crut.length ; i++ )
        if ( crut.charAt(i) != ' ' && crut.charAt(i) != '.' && crut.charAt(i) != '-' )
        {
            tmpstr = tmpstr + crut.charAt(i);
        }
        var rut = tmpstr;
        crut=tmpstr;
        largo = crut.length;

        if ( largo> 2 )
            rut = crut.substring(0, largo - 1);
        else
            rut = crut.charAt(0);

        var dv = crut.charAt(largo-1);

        if ( rut == null || dv == null )
        return 0;

        var dvr = '0';
        var suma = 0;
        var mul  = 2;

        for (var i= rut.length-1 ; i>= 0; i--)
        {
            suma = suma + rut.charAt(i) * mul;
            if (mul == 7)
                mul = 2;
            else
                mul++;
        }
        var res = suma % 11;
        if (res==1)
            var dvr = 'k';
        else if (res==0)
            dvr = '0';
        else
        {
            var dvi = 11-res;
            dvr = dvi + "";
        }

        if ( dvr != dv.toLowerCase() )
        {
            return false;
        }
        return true;
    }
}

handleAlert = (error) => {
    const swal = require('sweetalert2');
    var temp = []
    temp = error.split('\n').join('<br>')
    swal({
        title: "Se encontraron los siguientes errores.",
        html: temp,
        confirmButtonText: 'Aceptar',
    });   
}
makePass(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

async handleSubmit(e) {
    e.preventDefault();
    let valido = true
    let error = "" 
    this.setState({
        loader: true
    })
    if(this.state.rut === ""){
        valido = false;
        error += "Ingresa tu rut. \n"
    }
    if(await this.validarRut(this.state.rut) === false ){
        valido = false;
        error += "El rut no es correcto. \n"
    }
    else {
        let params = {
            email:'',
            password: ''
        }
        
        let savedrut = this.state.rut  

        let loggedagain = await requestHttp.getLoginData(savedrut)
        if(loggedagain){
            savedrut = savedrut.split(".").join("");

            let validateClient = await requestHttp.validateCustomer(savedrut)
            if (validateClient) {
                validateClient = caseToZoho(validateClient)
                
                let newpass = this.makePass(20)
                localStorage.setItem("newpass",newpass)
                let login =  loggedagain
                if (login /*&& login.length > 0*/) {
                    var ciphertext = CryptoJS.AES.encrypt(newpass, 'secret key 123');
                    
                    let params = {
                        "clave": ciphertext.toString()
                    }
                    let registro =  await requestHttp.putLog(login[0].id, params)
                    
                    if(registro){
                        let sendEmail = requestHttp.sendEmail(
                            validateClient.Email, newpass)
                        if(sendEmail){
                            notification.open({
                                message: 'Contraseña enviada',
                                description:
                                'Se ha enviado un correo con tu contraseña',
                            });
                            this.props.history.push("/")
                        }
                    }
                    else{
                        notification.open({
                            message: 'Ha ocurrido un error',
                            description:
                            'Ha ocurrido un error, por favor intentar mas tarde.',
                        });
                        this.setState({
                            loader: false
                        })
                    }
                }else{
                    notification.open({
                        message: 'Ha ocurrido un error',
                        description:
                        'Ha ocurrido un error, por favor intentar mas tarde.',
                    });
                    this.setState({
                        loader: false
                    })
                }
            }        
		    else {
			    notification.open({
			        message: 'Rut no encontrado',
			        description: 'El rut ingresado no se encuentra registrado en la plataforma'
			    })
			    this.setState({
			        loader: false
			    })
		    }
        }
        else{
            notification.open({
                message: 'Ha ocurrido un error',
                description:
                    'Usted no posee un correo registrado en nuestra base de datos. Por favor, solicite una clave a: sucursalvirtual@grupodefensa.cl',
            });
            await this.setState({
                loader: false
            })
        }
    }
}


  render() {
        return(

            <main>
                {this.state.loader ? <Loader /> : null}
                <div className="login-layout">
                    <Container>
                        <Row>
                            <Col xl={{size: "4", offset: "4"}} lg={{size: "4", offset: "4"}} md={{size: "6", offset: "3"}}>

                                <Form onSubmit={(e) => this.handleSubmit(e)} className="login-form">
                                    <FormGroup>
                                        <Link to="/" ><img src={require("../../assets/images/logo.png")} style={{maxWidth: "100%"}}/></Link>
                                        <hr className="orange-hr"/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h2>SUCURSAL VIRTUAL</h2>
                                        <br/>
                                    </FormGroup>
                                    <FormGroup><p style={{fontSize: "15px"}}>Si olvidaste tu contraseña debes ingresar tu RUT y la contraseña sera enviada a tu correo. Luego podrás cambiarla desde tu perfil.</p></FormGroup>
                                    <FormGroup>
                                        <Input placeholder="Rut" onChange={(e) =>this.handleChange(e.target)}  name="rut" value={this.state.rut} required/>
                                    </FormGroup>
                                    <br/>
                                    <button type="submit" className="main-btn" disabled={this.state.loader}>
                                        <span>Enviar</span>
                                    </button>
                                </Form>
                                <br/>
                                <br/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            <br/>
            <br/>
            </main>
        )
    } 
}
export default ForgetPassword;
