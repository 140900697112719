import React, { Component } from 'react'
import {Row, Container, Col, FormGroup, Input, Form} from 'reactstrap'
import { notification } from 'antd'
import { Link, Redirect } from 'react-router-dom'
import {requestHttp} from '../Services/request.service'
import Loader from '../Loader'
import ChangePass from './partials/ChangePass'
import { caseToZoho } from '../Services/client_data'
import '../../assets/css/login.css'
import '../../assets/css/tiny-slider.css'
var CryptoJS = require("crypto-js")

class WrappedLogin extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            rut:'',
            password:'',
            loader: false,
            isFirstTime: false,
            redirect: false,
            isSucursal: window.location.href.includes('grupodefensa')
        };
    }
    
    componentDidMount() {
        this.initLogin()
        let token = localStorage.getItem('token')
        
        this.setState({
            collapsed: false,
            rut: '',
            password:'',
            redirect: token ? true : false
        })
    }

    initLogin() {
        window.change = function (e) {
            if(document.getElementById("password").type == 'text') {
                document.getElementById("password").type = 'password';
                document.getElementById("image-pass").className = '';
            }
            else {
                document.getElementById("password").type= 'text';
                document.getElementById("image-pass").className = 'visible';
            }
        }
        window.getFW = function (width) {
            var sm = 400, md = 900, lg = 1400;
            return width < sm ? 150 : width >= sm && width < md ? 200 : width >= md && width < lg ? 300 : 400;
        }
        window.mobile = false;
        window.prefix = "";
        window.isTestPage = true;
        window.isDemoPage = false;
        window.classIn = 'tns-fadeIn';
        window.classOut = 'tns-fadeOut';
        window.speed = 0;
        window.ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        window.fw = window.getFW(window.ww);
        window.initFns = {};
        window.sliders = new Object();
        window.edgepadding = 50;
        window.gutter = 10;
        
        window.addEventListener('resize', function() { window.fw = window.getFW(window.ww); });

        window.options = {
            'carrusel': {
                items: 1,
                autoplayTimeout: 5000,
                mouseDrag: true,
                nav: false,
                controls: true,
                autoplay: false,
                loop: true,
                responsive: {
                    768: {
                        items: 2,
                        nav: false,
                        controls: true,
                        autoplay: true,
                        loop: true,
                    },
                    960: {
                        items: 3,
                        nav: false,
                        controls: true,
                        autoplay: true,
                        loop: true,
                    },
                    1300: {
                        items: 5,
                        nav: false,
                        controls: true,
                        autoplay: false,
                        loop: true,
                    }
                },
            }
        };
    }

    initCarrusel () {
        if (window.initCarruselSuccess) {
            return
        }

        console.log('init carrusel')
        
        for (var i in window.options) {
            var item = window.options[i];
            item.container = '#' + i;
            item.swipeAngle = false;
            
            if (!item.speed) {
                item.speed = window.speed;
            }
            
            var data_responsive = document.body.getAttribute('data-responsive');
            if (document.querySelector(item.container)) {
                window.sliders[i] = window.tns(item);
          
                if (window.initFns[i]) {
                    window.initFns[i]();
                }
            }
            else if (data_responsive) {
                var str = 'responsive' + data_responsive;
                if (i === str && window.initFns[i]) {
                    window.initFns[i]();
                }
            }
        }
        window.initCarruselSuccess = true
    }

    componentWillUnmount() {
        window.removeEventListener('resize', function() { window.fw = window.getFW(window.ww); })
        window.fw = null
        window.options = null
        window.initCarruselSuccess = null
    }

    formateaRut(rut) {
        try{
            var first = rut.charAt(0);
            if(!parseFloat(first)){
                console.log(rut)
                return rut;
            }
        }catch(ex){}

        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 1) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (var i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
            return rutPuntos;
        }
        return actual;
    }
    
    async handleChange(e){
        if(e.name === "rut"){
            let format = this.formateaRut(e.value)
            //console.log(e)
            await this.setState({
                rut: format || ''
            });
        }
        if(e.name === "rut_empresa"){
            let format = this.formateaRut(e.value)
            await this.setState({
                rut_empresa: format || ''
            });
        }
        else{
            await this.setState({
                [e.name] : e.value
            })
        }
    }
    
    
    async validarRut( Objeto ){
        var tmpstr = "";
        var intlargo = Objeto
        if(intlargo  ===  undefined || intlargo === ""){
            return false
        }
        if (intlargo.length > 0 )
        {
            var crut = Objeto
            var largo = crut.length;
            if ( largo <2 )
            {
                return false;
            }
            for (var i=0; i <crut.length ; i++ )
            if ( crut.charAt(i) != ' ' && crut.charAt(i) != '.' && crut.charAt(i) != '-' )
            {
                tmpstr = tmpstr + crut.charAt(i);
            }
            var rut = tmpstr;
            crut=tmpstr;
            largo = crut.length;
            
            if ( largo> 2 )
            rut = crut.substring(0, largo - 1);
            else
            rut = crut.charAt(0);
            
            var dv = crut.charAt(largo-1);
            
            if ( rut == null || dv == null )
            return 0;
            
            var dvr = '0';
            var suma = 0;
            var mul  = 2;
            
            for (var i= rut.length-1 ; i>= 0; i--)
            {
                suma = suma + rut.charAt(i) * mul;
                if (mul == 7)
                mul = 2;
                else
                mul++;
            }
            var res = suma % 11;
            if (res==1)
            var dvr = 'k';
            else if (res==0)
            dvr = '0';
            else
            {
                var dvi = 11-res;
                dvr = dvi + "";
            }
            
            if ( dvr != dv.toLowerCase() )
            {
                return false;
            }
            return true;
        }
    }

    async validEmail( email){
        
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return true
        }
        return false
    }
    
    handleAlert = (error) => {
        const swal = require('sweetalert2');
        var temp = []
        temp = error.split('\n').join('<br>')
        swal({
            title: "Se encontraron los siguientes errores.",
            html: temp,
            confirmButtonText: 'Aceptar',
        });   
    }
    
    async handleSubmit(e) {
        e.preventDefault();
        
        this.setState({
            loader:true
        })
        let valido = true
        let error = "" 
        // AQUI INCORPORAR REVISION DE LOGIN DE ADMIN
        if ( await this.validEmail(this.state.rut) ){
            // Es posible que sea un usuario
            if(this.state.password === ""){
                this.setState({ error: [], loader: false })
                this.handleAlert("Ingresa tu contraseña. \n")
                return false;
            }

            try{
                let user  = await requestHttp.validateUser(this.state.rut,this.state.password)
                console.log(user);
                if(user){

                    localStorage.setItem('Admin', 'si')

                    
                    sessionStorage.setItem('validateClient', "admin")
                    sessionStorage.setItem('user', "admin")
                    sessionStorage.setItem("datosusuario", "admin")
                    localStorage.setItem('token', "admin")
                    localStorage.setItem('rut', "admin")
                    sessionStorage.setItem('causas','admin')
                    localStorage.setItem('postDueAlertShow', '1')
                    
                    
                    notification.open({
                        message: 'Sesión iniciada',
                        description:
                        'Bienvenido a Grupo Defensa ',
                    });
                
                    this.props.history.push("/admin/causes")
                    window.location.reload()

                }
            }catch(ex){
                this.setState({
                    loader: false
                })
                console.log(ex);
            }


        }


        if(await this.validarRut(this.state.rut) === false ){
            valido = false;
            error += "El rut no es correcto. \n"
        }
        
        if(this.state.password === ""){
            valido = false;
            error += "Ingresa tu contraseña. \n"
        }
        if( !valido ){
            var array = new Array()
            array = error.split("\n")
            array.splice( array.length-1)
            for(var i = 0; i < array.length; i++){
                this.setState({
                    error: array,
                    loader: false
                })
            }
            this.handleAlert(error)
            return false;
        }
        else{
            let rutCliente = this.state.rut
            rutCliente = rutCliente.split(".").join("");
            
            let validateClient = await requestHttp.validateCustomer(rutCliente)
            
            let name = ""
            console.log("validateClient")
            console.log(validateClient)

            if (validateClient) {
                const _validateClient = caseToZoho(validateClient)

                name = _validateClient.First_Name
                let user  = await requestHttp.getLoginData(this.state.rut)
                
                if (user) {

                    sessionStorage.setItem('validateClient', JSON.stringify(validateClient))
                    sessionStorage.setItem('user', JSON.stringify(user))
                    localStorage.setItem('Admin', 'no')

                    if (user.length < 1) {
                        let defaultPass = rutCliente.substring(0, 4)
                        if (this.state.password !== defaultPass) {
                            this.setState({
                                loader: false
                            })
                            notification.open({
                                message: 'Error al ingresar',
                                description:
                                'La contraseña no es correcta',
                            });
                        }
                        else {
                            var ciphertext = CryptoJS.AES.encrypt(this.state.password, 'secret key 123');
                            let registro =  await requestHttp.login(this.state.rut, ciphertext.toString())
                            
                            if (registro) {
                                notification.open({
                                    message: 'Sesión iniciada',
                                    description:
                                    'Bienvenido a Grupo Defensa ' + name ,
                                });
                                
                                sessionStorage.setItem("datosusuario", JSON.stringify(user[0]))
                                localStorage.setItem('token', this.state.rut)
                                localStorage.setItem('Admin', 'no')
                                await this.setState({
                                    isFirstTime: true,
                                    loader: false
                                })
                            }
                        }
                    }
                    else{
                        let login = user
                        
                        if (login && login.length > 0) {
                            var bytes  = CryptoJS.AES.decrypt(login[0].clave.toString(), 'secret key 123') ;
                            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
                            
                            if (this.state.password === plaintext) {
                                sessionStorage.setItem("datosusuario", JSON.stringify(login[0]))
                                localStorage.setItem('token', this.state.rut)
                                const rut = login[0].rut.replace(/\./g,'').replace('-', '')
                                //document.getElementById('rut').value = rut
                                localStorage.setItem('rut', rut)
                                localStorage.setItem('postDueAlertShow', '1')
                                localStorage.setItem('Admin', 'no')
                                
                                notification.open({
                                    message: 'Sesión iniciada',
                                    description:
                                    'Bienvenido a Grupo Defensa ' + name,
                                });
                            
                                this.props.history.push("/admin/causes")
                                //window.location.reload()
                            }
                            else {
                                this.setState({
                                    loader: false
                                })
                                notification.open({
                                    message: 'Error al ingresar',
                                    description:
                                    'La contraseña no es correcta',
                                });
                            }
                        }
                    }
                }
                else {
                    this.setState({
                        loader: false
                    })
                    notification.open({
                        message: 'Error al ingresar',
                        description:
                        'El rut no esta registrado en nuestra plataforma.',
                    });   
                }
            }
            else{
                this.setState({
                    loader: false
                })
                notification.open({
                    message: 'Error al ingresar',
                    description:
                    'El rut no esta registrado en nuestra plataforma.',
                });
            }
        }
    }
    
    render() {
        setTimeout(() => {
            this.initCarrusel()
        }, 500)

        const isSucursal = this.state.isSucursal
        console.log(isSucursal)


        return (
            <main>
            {this.state.loader ? <Loader /> : null}
            {this.state.redirect ? <Redirect to="/admin/causes" /> : null}
            <header>
			    <img className="logo-header" src={require(isSucursal ? '../../assets/images/logo.svg' : '../../assets/images/login/logo-defensa_deudores.svg')} />
		    </header>
		    <section className="formulario">
			    <div className="inner-formulario">
				    <div className="izquierda">
					    <img className="img-form" src={require('../../assets/images/login/imagen.jpg')} />
				    </div>
				    <div className="derecha">
					    <img className="logo-formulario" src={require(isSucursal ? '../../assets/images/login/logo-sucursal_virtual_blue.svg' : '../../assets/images/login/logo-sucursal_virtual.svg')} />
					    <form onSubmit={(e) => this.handleSubmit(e)}>
                            <div className="form-grupo">
                                <label>RUT</label>
                                <input onChange={(e) =>this.handleChange(e.target)} type="text" name="rut" id="rut" value={this.state.rut} />
                            </div>
                            <div className="form-grupo">
                                <label>Contraseña</label>
                                <div className="password-box">
                                    <input onChange={(e) =>this.handleChange(e.target)} type="password" name="password" id="password" value={this.state.password} />
                                    <div onClick={(e) => window.change(e)} id="image-pass">
                                    </div>
                                </div>
                                <a href="/forgot-password" className="small small-right">¿Olvidaste tu contraseña?</a>
                            </div>
                            <div className="form-grupo">
                                <input className={isSucursal ? 'btn blue' : 'btn'} type="submit" value="Ingresar" />
                            </div>
					    </form>
				    </div>
			    </div>
		    </section>
		    <section className={isSucursal ? 'carrusel-section blue' : 'carrusel-section'}>
			    <div id="carrusel_wrapper">
		            <div className="carrusel" id="carrusel">
		                <div className="item">
		                    <img src={require('../../assets/images/login/icono-consultas_causas.svg')} className="item-icono" />
		                    <h3 className="item-titulo">Consulta de Causas</h3>
		                    <p className="item-contenido">Información y Seguimiento simple y fácil de comprender en tiempo real 24/7.</p>
		                </div>
		                <div className="item">
		                    <img src={require('../../assets/images/login/icono-consulta_tramites.svg')} className="item-icono" />
		                    <h3 className="item-titulo">Consulta de Trámites y Escrituras Públicas</h3>
		                    <p className="item-contenido"></p>
		                </div>
		                <div className="item">
		                    <img src={require('../../assets/images/login/icono-gestiona_cuenta.svg')} className="item-icono" />
		                    <h3 className="item-titulo">Gestiona tu Cuenta</h3>
		                    <p className="item-contenido">Revisa próximos vencimientos o consulta pagos anteriores.</p>
		                </div>
		                <div className="item">
		                    <img src={require('../../assets/images/login/icono-chat_online.svg')} className="item-icono" />
		                    <h3 className="item-titulo">Chat Online</h3>
		                    <p className="item-contenido">Conéctate con un Ejecutivo de Servicio al Cliente al instante.</p>
		                </div>
		                <div className="item">
		                    <img src={require('../../assets/images/login/icono-pago_online.svg')} className="item-icono" />
		                    <h3 className="item-titulo">Pago Online</h3>
		                    <p className="item-contenido">*Muy Pronto.</p>
		                </div>
		            </div>
		        </div>
		    </section>
		    <section className="footer-section">
                <p>Si necesitas ayuda, contáctate al <a href="Tel:6005719300">600 5719 300</a> o escríbenos a <a href="mailto:soporte@defensadeudores.cl">soporte@defensadeudores.cl</a></p>
            </section>
        </main>)
    } 
}

export default WrappedLogin;
        