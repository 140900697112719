import React, { Component } from 'react';
import { Table, Icon, notification} from 'antd';
import moment from 'moment';
import Swal from 'sweetalert2'
import {requestHttp} from '../Services/request.service';
import Loader from '../Loader';
import {
	isMobileOnly
} from "react-device-detect";

const { Column } = Table;
class Causes extends Component{
    constructor(props) 
    {
        super(props)
        this.state = {
            isOpen: false,
            isLogged: false,
            loader: true,
            data:[],
            late: false,
            name: "",
            checkBaja: false,
            checkBajaMensaje: "",
            ViajeCliente:[]
        };
    }

    async componentDidMount(){
        let savedrut = null;
        let loggedagain = null;
        let params = {
            email:'',
            password: ''
        }

        if(localStorage.getItem('Admin') =='si'){
            if(localStorage.getItem("rutBuscado")){
                savedrut = localStorage.getItem("rutBuscado");
            }
        }
        loggedagain = await requestHttp.loggedAgain(params.email, params.password)
        sessionStorage.setItem('loggedagain', JSON.stringify(loggedagain))
   
        
        let Message = 'Ha ocurrido un error';
        let Description = 'Ha ocurrido un error, intentelo más tarde';
        /** AQUI VERIFICAR QUE EL CLIENTE ES BAJA */
        let getCheckBaja =  await requestHttp.getCheckBaja(savedrut)
        //getCheckBaja.baja=true;
        //getCheckBaja.mensaje="Alerta de prueba, es baja";
        if(getCheckBaja.baja==true){
            loggedagain = false;
            Message = "Atencion";
            Description = getCheckBaja.mensaje;

            await this.setState({
                checkBaja: true,
                checkBajaMensaje: getCheckBaja.mensaje
            })
        }

        
        if (loggedagain) {
            let token = loggedagain.token

            var periodo = "2022-08";
            let dataPdf = await requestHttp.getMonthlyManagementPdfRutAll( savedrut/*"14332660-8"*/,periodo)
            if(dataPdf.length>0){
                await this.setState({ ViajeCliente: dataPdf, loader: false })
            }else{
                notification.open({
                    message: 'Sin movimientos en sus Causa(s).',
                    description:'No Registra movimientos en su causa(s)',
                });
                await this.setState({ loader: false })
            }
        }
        else{
            notification.open({
                message: Message,
                description: Description,
            });
            await this.setState({
                loader: false
            })
        }
    }
    
    render(){
        const causas = this.state.ViajeCliente;

        return(
            <div>
                {this.state.checkBaja ? 
                
                <div className="admin-container">
                    {this.state.loader ? <Loader /> : null}
                    <h4> Atención !   <small> {this.state.checkBajaMensaje} </small></h4>
                </div>
                :
                <div className="admin-container">
                    {this.state.loader ? <Loader /> : null}
                    <h4>Mis movimientos  <small> (última actualización: {moment().subtract(1, 'days').format('DD-MM-YYYY')})</small></h4>

                    <br/>
                    <div className="bg-gray table-responsive" style={{border: "1px solid transparent"}}>
                        {causas.length > 0 ?
                        <Table dataSource={causas} pagination={false}>
                            <Column title="Rol"
                                key="6"
                                render={(text, record, index) => (
                                    <span>
                                        <p> {!record.ROL ? '-' : record.ROL }</p>
                                    </span>
                                )}
                            />
                            <Column title="Caratulado" dataIndex="title" key="1" render={(text, record, index) => (
                                    <span>
                                    <p> {!record.caratulado ? '-' : record.caratulado }</p>
                                    </span>
                                )}
                            />
                            <Column title="Nombre" dataIndex="title" key="1" render={(text, record, index) => (
                                    <span>
                                        <p> {record.Descripcion}</p>
                                    </span>
                                )}
                            />
                            <Column title="Detalle" dataIndex="title" key="1" render={(text, record, index) => (
                                    <span>
                                    <p> {!record.detalle ? '-' : record.detalle }</p>
                                    </span>
                                )}
                            />
                            
                            {
                                isMobileOnly
                                ? null
                                : <Column
                                    title="Fecha último movimiento"
                                    key="4"
                                    render={(text, record, index) => (
                                    <span>
                                        { record.Fecha_insercion ? record.Fecha_insercion : "-"}
                                    </span>
                                    )}
                                />
                            }
                           
                        </Table> : <p>Proceso en tramitación normal</p> }
                        <br/>
                    </div>
                </div>
                }
            </div>
        )
    }
}
export default Causes;
