import React, { Component } from 'react';
import {CustomInput, Row, Col, FormGroup, Input, Form, Label} from 'reactstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {requestHttp} from '../Services/request.service';
import * as RTable from 'react-super-responsive-table';
import { Table, Tabs, notification, Empty } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';

const { Column } = Table;
const { TabPane } = Tabs;

class Archivo extends Component{
    constructor(props) 
    {
        super(props)
        this.state = {
            file: null,
            fileName: '',
            fileType: '',
            invalidFile: false,
            listaArchivos: [],

            isOpen: false,
            isLogged: false,
            loader: true,
            data:[],
            late: false,
            reload:false,
            rut: "",
            nombre: "",
            url_sac: "https://agendasac.gdef.cl/index.php?route=makeanappointment-frame-new&empresa=dd"
        };
    }

    handleFileChange = this.handleFileChange.bind(this);
    async handleFileChange({target: {files}}) {
      const cancel = !files.length;
      if (cancel) return;
  
      const [{ size, name }] = files;
      window.filex = files;
      const maxSize = 11000000;
      if (size < maxSize) {
        this.setState({fileName:name, invalidFile:false, file: files[0]});
      } else {
        alert("Archivo muy grande, maximo 10mb")
        this.setState({fileName:'', invalidFile:true,file: null});
      }
    }

    async handleChange(e){
        await this.setState({fileType : e.value})
    }

    async handleSubmit(e) {
        e.preventDefault();
        if(this.state.file!=null && (this.state.fileType.length>0 && this.state.fileType!='seleccione')){
            /*const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });*/


            let user  = JSON.parse(sessionStorage.getItem('user'));
            let userB = JSON.parse(sessionStorage.getItem('validateClient'));
            var res = await requestHttp.sendFile({file:this.state.file,fileName:this.state.fileName,fileType:this.state.fileType, id:user[0].id, identifier:userB.identifier})
            console.log("res")
            console.log(res)
            if(res.name.length>0)
                alert(res.name+ " enviado");
            /*
            var res = await requestHttp.sendFile({file:await  toBase64(this.state.file),fileName:this.state.fileName,fileType:this.state.fileType, id:user[0].id, identifier:userB.identifier})
            if(res=="1"){ 
                alert("Documento enviado existosamente");
                var resB = await requestHttp.sendFile({id:user[0].id, identifier:userB.identifier,list:1})
                    if(resB=="-1"){
                        console.log("sin archivos");
                    }else{
                        await this.setState({listaArchivos : resB})
                    }
                }
            if(res=="0") alert("Solo se permiten imagenes, pdf y docx");
            if(res=="-1") alert("Error del sistema, comuniquese con el proveedor");
            */

        }else{
            alert("Archivo no seleccionado")
        }
    }


    async componentDidMount(){

        let user  = JSON.parse(sessionStorage.getItem('user'));
        let userB = JSON.parse(sessionStorage.getItem('validateClient'));
        var res = await requestHttp.sendFile({id:user[0].id, identifier:userB.identifier,list:1})
        if(res=="-1"){
            console.log("sin archivos");
        }else{
            await this.setState({listaArchivos : res})
        }
        /*
        if(res=="1") alert("Documento enviado existosamente");
        if(res=="0") alert("Solo se permiten imagenes, pdf y docx");
        if(res=="-1") alert("Error del sistema, comuniquese con el proveedor");
        */
        /*let validateClient = null;
        try {
            validateClient = JSON.parse(sessionStorage.getItem('validateClient'))
            //email: validateClient.Email, mail
            //phone: validateClient.Phone, mobile
            let url_sac = this.state.url_sac
            if(validateClient.identifier!=null)
                url_sac=url_sac+"&rut="+validateClient.identifier
            if(validateClient.name!=null)
                url_sac=url_sac+"&nombre="+validateClient.name
            if(validateClient.Phone!=null)
                url_sac=url_sac+"&mobile="+validateClient.Phone
            if(validateClient.Email!=null)
                url_sac=url_sac+"&mail="+validateClient.Email
            this.setState({ url_sac})
            //this.setState({ url_sac: url_sac+"&rut="+validateClient.identifier+"&nombre="+validateClient.name+"&mobile="+validateClient.Phone+"&mail="+validateClient.Email})
        }
        catch (err) {
            console.log(err)
            validateClient = null
        }*/
        
    }

    render(){
        return(
        <div className="admin-container">
            <h4>Subir Archivo</h4>
            <Form className="profile-form">
                <Row>
                    <Col xl="4" lg="4">
                        <Label for="exampleSelect"> Archivo </Label>
                        <CustomInput
                            type="file"
                            id="exampleCustomFileBrowser"
                            name="customFile"
                            label={this.state.fileName || 'buscar archivo'}
                            onChange={this.handleFileChange}
                            invalid={this.state.invalidFile} />
                    </Col>
                    <Col xl="4" lg="4">
                    
                    <Label for="exampleSelect"> Tipo </Label>
                    <Input onChange={(e) =>this.handleChange(e.target)} id="exampleSelect"name="select"type="select">
                        <option>seleccione</option>
                        <option>Cedula</option>
                        <option>Certificado</option>
                        <option>Otros</option>
                    </Input>
                    </Col>
                    <Col xl="4" lg="4">
                        <FormGroup>
                            <button onClick={(e)=>this.handleSubmit(e) } className="main-btn">
                                <span>Subir</span>
                            </button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            
            <Tabs defaultActiveKey="1" >
                    <TabPane tab="Lista de Archivos" key="1">
                        {
                            (this.state.listaArchivos || []).length > 0
                            ? <div className="table-responsive">
                                {
                                    isMobileOnly
                                    ? <RTable.Table>
                                        <RTable.Thead>
                                            <RTable.Tr>
                                                <RTable.Th>N°</RTable.Th>
                                                <RTable.Th>Archivo</RTable.Th>
                                                <RTable.Th>Descargar</RTable.Th>
                                                <RTable.Th>Borrar</RTable.Th>
                                            </RTable.Tr>
                                        </RTable.Thead>
                                        <RTable.Tbody>
                                            {
                                                this.state.listaArchivos.map(record => <RTable.Tr>
                                                    <RTable.Td>
                                                        <span>
                                                        { record}
                                                        </span>
                                                    </RTable.Td>
                                                    <RTable.Td>
                                                        <span>
                                                        {record}
                                                        </span>
                                                    </RTable.Td>
                                                    <RTable.Td>
                                                        <span>
                                                        
                                                        </span>
                                                    </RTable.Td>
                                                    <RTable.Td>
                                                        <span>
                                                        
                                                        </span>
                                                    </RTable.Td>
                                                    
                                                </RTable.Tr>)
                                            }
                                        </RTable.Tbody>
                                        </RTable.Table>
                                    : <Table dataSource={this.state.listaArchivos} pagination={false} fixed={"left"}>
                                        <Column title="Nombre" dataIndex="title" key="1" render={(text, record, index) => (
                                                <span>
                                                    { record}
                                                </span>
                                            )}
                                        />
                                        
                                    </Table>
                                }
                            </div>
                            : <Empty description="No hay datos" />
                        }
                        
                    </TabPane>
                </Tabs>


        </div>)
    }
}
export default Archivo;
