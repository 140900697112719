import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();

const rutHidden = document.createElement('input')
rutHidden.type = 'hidden'
rutHidden.id = 'rut'

/*
if (localStorage.getItem('rut')) {
    rutHidden.value = localStorage.getItem('rut')
    const chat = document.createElement('script')
    //chat.src = 'https://widget02.wolkvox.com/chat/?prodId=Z2RlZmVuc2Etc2FjMS4xNDcw'
    document.body.appendChild(chat)
    document.body.appendChild(rutHidden)
}
else
{
    const chat = document.createElement('script')
    chat.src = ''
    document.body.appendChild(chat)
    document.body.appendChild(rutHidden)
}
*/