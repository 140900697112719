import React, { Component } from 'react';
import { Table, Tabs, notification, Empty } from 'antd';
import moment from 'moment';
import {requestHttp} from '../Services/request.service';
import Loader from '../Loader';
import * as RTable from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { isMobileOnly } from 'react-device-detect';

const { Column } = Table;
const { TabPane } = Tabs;

class Pending extends Component{
    constructor(props) 
    {
        super(props)
        this.state = {
            isOpen: false,
            isLogged: false,
            loader: true,
            data:[],
            late: false
        };
    }

    async componentDidMount(){
        let savedrut = localStorage.getItem('token')
        savedrut = savedrut.split(".").join("");

        let historialCliente = sessionStorage.getItem('history')

        if (historialCliente) {
            historialCliente = JSON.parse(historialCliente)
        }
        else {
            try {
                historialCliente =  await requestHttp.customerHistory(savedrut)

	            if (!historialCliente.historial_manager) {
	                historialCliente = null
                }
                else {
                    sessionStorage.setItem('history', JSON.stringify(historialCliente))
                }
            }
            catch (err) {
	            notification.open({
                    message: 'No registra pagos pendientes',
                    description: 'No hay pagos pendientes asociados a su rut',
                });
                

                await this.setState({
                    loader: false
                })

            }
        }

        if (historialCliente) {
            let hist = []
            let pay = []
            let vencido = false;

            historialCliente.historial_manager.forEach(element => {
                if (element.detalle._text === "PENDIENTE"){
                    let vencimiento = moment(element.vencimiento._text, "DD-MM-YYYY hh:mm:ss")
                    if(moment().diff(vencimiento, "days") < 0){
                        Object.assign(element, {"text": "Por vencer"})
                    }
                    else{
                        Object.assign(element, {"text": "Vencido"})
                        vencido = true;
                    }
                    hist.push(element)
                }
                else{
                    pay.push(element)
                }
            });
            await this.setState({
                loader: false,
                data: hist,
                pay: pay,
                late: vencido
            })
            console.log("hist");
            console.log(hist);
            console.log("hist");
        }
    }

    render(){
        return(
            <div className="admin-container">
                {this.state.loader ? <Loader /> : null}
                { this.state.late ? <div className="late-tag"><h6>Usted tiene cuotas vencidas.</h6><br/> <a href="https://pagos.defensadeudores.cl/" style={{lineHeight: "34px", width: "250px", paddingTop: "6px"}} target="_blank" className="main-btn">Ir al Portal de Pagos</a> </div> : null }
                <h4>Mi cuenta corriente</h4>
                <br/>
                <p><strong>Importante: Los pagos realizados se verán reflejados dentro de las 24 horas siguientes a haber sido efectuados.</strong></p>
                <div className="bg-gray" style={{border: "1px solid transparent"}}>

                { this.state.late ? <a href="https://pagos.defensadeudores.cl/" style={{lineHeight: "34px", width: "250px", paddingTop: "6px"}} target="_blank" className="main-btn">Ir al Portal de Pagos</a>
                    /*<div className="card">
                        <div className="container">
                            <h4><b>Campaña Ponte al día. Obtén hasta un 20 % de descuento</b></h4>
                            <p>Si pagas 1 cuota atrasada 10 % descuento</p>
                            <p>Si pagas dos cuotas atrasadas 15% </p>
                            <p>Si pagas tres cuotas atrasadas 20% descuento</p>
                            <a href="https://pagos.defensadeudores.cl/" style={{lineHeight: "34px", width: "250px"}} target="_blank" className="main-btn">Ir al Portal de Pagos</a>
                            <p></p>
                        </div>
                    </div>*/
                : <a href="https://pagos.defensadeudores.cl/" style={{lineHeight: "34px", width: "250px", paddingTop: "6px"}} target="_blank" className="main-btn">Ir al Portal de Pagos.</a> }
                <p></p>
                

                <Tabs defaultActiveKey="2" >
                    {/*<TabPane tab="Pendientes" key="1">
                        {
                            (this.state.data || []).length > 0
                            ? <div className="table-responsive">
                                {
                                    isMobileOnly
                                    ? <RTable.Table>
                                        <RTable.Thead>
                                            <RTable.Tr>
                                                <RTable.Th>Nombre</RTable.Th>
                                                <RTable.Th>Monto</RTable.Th>
                                                <RTable.Th>Vencimiento</RTable.Th>
                                                <RTable.Th>Estado</RTable.Th>
                                                <RTable.Th>Pagar</RTable.Th>
                                            </RTable.Tr>
                                        </RTable.Thead>
                                        <RTable.Tbody>
                                            {
                                                this.state.data.map(record => <RTable.Tr>
                                                    <RTable.Td>
                                                        <span>
                                                        { record.glosa._text }
                                                        </span>
                                                    </RTable.Td>
                                                    <RTable.Td>
                                                        <span>
                                                        $ {record.monto._text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                        </span>
                                                    </RTable.Td>
                                                    <RTable.Td>
                                                        <span>
                                                        <p>{ moment(record.vencimiento._text, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY") } </p>
                                                        </span>
                                                    </RTable.Td>
                                                    <RTable.Td>
                                                        <span>
                                                        {  record.text === "Vencido" ? <span style={{color: "#e13838"}}>{record.text}</span> : record.text }
                                                        </span>
                                                    </RTable.Td>
                                                    <RTable.Td>
                                                        <span>
                                                        { record.detalle._text === "PENDIENTE" ? <span></span> : <span>Pagado</span>}
                                                        </span>
                                                    </RTable.Td>
                                                </RTable.Tr>)
                                            }
                                        </RTable.Tbody>
                                        </RTable.Table>
                                    : <Table dataSource={this.state.data} pagination={false} fixed={"left"}>
                                        <Column title="Nombre" dataIndex="title" key="1" render={(text, record, index) => (
                                                <span>
                                                    { record.glosa._text }
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title="Monto"
                                            key="2"
                                            render={(text, record, index) => (
                                                <span>
                                                $ {record.monto._text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title="Vencimiento"
                                            key="4"
                                            render={(text, record, index) => (
                                                <span>
                                                    <p>{ moment(record.vencimiento._text, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY") } </p>
                                                </span>
                                            )}
                                        />
                                        <Column title="Estado"
                                            key="3"
                                            render={(text, record, index) => (
                                                <span>
                                                    {  record.text === "Vencido" ? <span style={{color: "#e13838"}}>{record.text}</span> : record.text }
                                                </span>
                                            )}
                                        />
                                        
                                        <Column
                                            title="Pagar"
                                            key="6"
                                            render={(text, record, index) => (
                                                <span>
                                                    { record.detalle._text === "PENDIENTE" ? <span></span> : <span>Pagado</span>} 
                                                </span>
                                            )}
                                        />
                                            
                                    </Table>
                                }
                            </div>
                            : <Empty description="No hay datos" />
                        }
                        
                    </TabPane>*/}
                    <TabPane tab="Historial" key="2">
                        {
                            (this.state.pay || []).length > 0
                            ? <div className="table-responsive">
                                {
                                    isMobileOnly
                                    ? <RTable.Table>
                                    <RTable.Thead>
                                        <RTable.Tr>
                                            <RTable.Th>Nombre</RTable.Th>
                                            <RTable.Th>Monto</RTable.Th>
                                            <RTable.Th>Vencimiento</RTable.Th>
                                            <RTable.Th>Fecha Pago</RTable.Th>
                                            <RTable.Th>Estado</RTable.Th>
                                        </RTable.Tr>
                                    </RTable.Thead>
                                    <RTable.Tbody>
                                        {
                                            this.state.pay.map(record => <RTable.Tr>
                                                <RTable.Td>
                                                    <span>
                                                    { record.glosa._text }
                                                    </span>
                                                </RTable.Td>
                                                <RTable.Td>
                                                    <span>
                                                    $ {record.monto._text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                    </span>
                                                </RTable.Td>
                                                <RTable.Td>
                                                    <span>
                                                    <p>{ moment(record.vencimiento._text, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY") } </p>
                                                    </span>
                                                </RTable.Td>
                                                <RTable.Td>
                                                    <span>
                                                    { record.detalle._text !== "Pendiente" ? <span>{moment(record.fechapago._text, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")}</span> : <span> - </span> }
                                                    </span>
                                                </RTable.Td>
                                                <RTable.Td>
                                                    <span>
                                                    { record.detalle._text }
                                                    </span>
                                                </RTable.Td>
                                            </RTable.Tr>)
                                        }
                                    </RTable.Tbody>
                                    </RTable.Table>
                                    : <Table dataSource={this.state.pay} pagination={false} fixed={"left"}>
                                        <Column title="Nombre" dataIndex="title" key="1" render={(text, record, index) => (
                                                <span>
                                                    { record.glosa._text }
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title="Monto"
                                            key="2"
                                            render={(text, record, index) => (
                                                <span>
                                                $ {record.monto._text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title="Vencimiento"
                                            key="4"
                                            render={(text, record, index) => (
                                                <span>
                                                    <p>{ moment(record.vencimiento._text, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY") } </p>
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title="Fecha pago"
                                            key="5"
                                            render={(text, record, index) => (
                                                <span>
                                                    { record.detalle._text !== "Pendiente" ? <span>{moment(record.fechapago._text, "DD-MM-YYYY hh:mm:ss").format("DD-MM-YYYY")}</span> : <span> - </span> }
                                                </span>
                                            )}
                                        />
                                        <Column title="Estado"
                                            key="3"
                                            render={(text, record, index) => (
                                                <span>
                                                    { record.detalle._text }
                                                </span>
                                            )}
                                        />
                                    </Table>
                                }
                            </div>
                            : <Empty description="No hay datos" />
                        }
                    </TabPane>
                </Tabs>
            </div>
        </div>)
    }
}
export default Pending;
