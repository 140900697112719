import React, { Component } from 'react';
import { Table, Tabs, notification } from 'antd';
import moment from 'moment';
import {requestHttp} from '../Services/request.service';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Iframe from 'react-iframe';


class Agenda extends Component{
    constructor(props) 
    {
        super(props)
        this.state = {
            isOpen: false,
            isLogged: false,
            loader: true,
            data:[],
            late: false,
            reload:false,
            rut: "",
            nombre: "",
            url_sac: "https://agendasac.gdef.cl/index.php?route=makeanappointment-frame-new&empresa=dd",
            openIframe: false,
            checkBajaMensaje: ""
        };
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem("validateClient") )
        let getCheckBaja =  await requestHttp.getCheckBaja(user.identifier)
        console.log(getCheckBaja)
        //getCheckBaja.baja=true;
        //getCheckBaja.mensaje="ALERTA DE prueba, es baja";

        await this.setState({ openIframe: !getCheckBaja.baja})
        
        if(getCheckBaja.baja)
            await this.setState({ checkBajaMensaje: getCheckBaja.mensaje })

            
        let validateClient = null;
        try {
            validateClient = JSON.parse(sessionStorage.getItem('validateClient'))
            //email: validateClient.Email, mail
            //phone: validateClient.Phone, mobile
            let url_sac = this.state.url_sac
            if(validateClient.identifier!=null)
                url_sac=url_sac+"&rut="+validateClient.identifier
            if(validateClient.name!=null)
                url_sac=url_sac+"&nombre="+validateClient.name
            if(validateClient.Phone!=null)
                url_sac=url_sac+"&mobile="+validateClient.Phone
            if(validateClient.Email!=null)
                url_sac=url_sac+"&mail="+validateClient.Email
            this.setState({ url_sac})
            //this.setState({ url_sac: url_sac+"&rut="+validateClient.identifier+"&nombre="+validateClient.name+"&mobile="+validateClient.Phone+"&mail="+validateClient.Email})
        }
        catch (err) {
            console.log(err)
            validateClient = null
        }
        
    }

    render(){
        return(
        <div className="admin-container">
            
            {
                this.state.openIframe
                ?  
                <iframe src={this.state.url_sac} security = "restricted" style={{width:'60%', height:'920px', border:'none'}} title="Agenda"
                referrerPolicy="same-origin"
                sandbox="
                        allow-forms 
                        allow-modals
                        allow-orientation-lock
                        allow-pointer-lock
                        allow-popups
                        allow-popups-to-escape-sandbox
                        allow-presentation
                        allow-same-origin
                        allow-scripts
                        allow-top-navigation
                        allow-top-navigation-by-user-activation
                        " 
                >
                </iframe>
                : 
                <div className="admin-container">
                    <h4> Atención !   <small> {this.state.checkBajaMensaje} </small></h4>
                </div>
                    
            }
        </div>)
    }
}
export default Agenda;
