import React, { Component } from 'react';
import { Drawer, Menu, Icon, Button, Input, notification} from 'antd';
import { Link, Redirect } from 'react-router-dom';
import {requestHttp} from '../Services/request.service';
import {
    Navbar,
    Nav,
    Col,
    Row,
    Collapse, NavItem
} from 'reactstrap';

export default class AdminContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            isOpen: false,
            redirect: false,
            default: NaN,
            username: "",
            responsive:false
        };
    }

    showChat () {
	    this.finishChatLoad()
    }

    chatOnLoad() {
	    setTimeout(() => this.finishChatLoad(), 1000)
    }

    finishChatLoad () {
        const styles = `
            div.cx-widget.cx-window-manager.cx-theme-light {
                display: block !important;
            }`
        
        const tag = document.createElement('style')
        tag.id = 'show-chat'
        tag.innerHTML = styles
        document.head.appendChild(tag)
    }

    hideChat () {
        document.getElementById('show-chat').remove()
    }

    async componentWillMount() {
        this.showChat()
        let token = localStorage.getItem('token')
        
        if (!token) {
            await this.setState({
                redirect: true
            })
        }
        else {
            let rut = localStorage.getItem('token')
            let savedrut = rut.split(".").join("")

            let user = JSON.parse(sessionStorage.getItem('user'))
            
            if ((user || [].length === 0)) {
                let validateClient = await requestHttp.validateCustomer(savedrut)
                user = await requestHttp.getLoginData(rut)
                
                sessionStorage.setItem('validateClient', JSON.stringify(validateClient))
                sessionStorage.setItem('user', JSON.stringify(user))
                sessionStorage.setItem("datosusuario", JSON.stringify(user[0]))
            }


            if(user){
                await this.setState({
                    username: user[0].nombres
                })
            }
            
            let WindowWidth = window.innerWidth
            if(WindowWidth < 767){
                await this.setState({
                    responsive: true,
                    collapsed: false
                })
            }
        }

        let pathname = this.props.location.pathname;
        if(pathname != null){
            this.setState({
                test: pathname
            });
        }
    }

    async componentWillReceiveProps(next){
        let user = await JSON.parse(sessionStorage.getItem("user"));
        if(user){
            var username =  user[0].nombres
            await this.setState({
                username: username
            })
        }
        let pathname = this.props.location.pathname;
        if(pathname != null){
            this.setState({
                test:pathname
            });
        }
    }
    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    async salir() {
        this.hideChat()
        sessionStorage.clear()
        localStorage.clear()
        try{
        document.getElementById('frameChatWolkvox').remove()
        }catch(e){}
    }
    Clicked(e){
        console.log(e)
    }

    toggle() {
        this.setState({
            collapsed: true
        });
    }

    isPending() {
        return window.location.pathname.includes('pending')
    }

    renderEvents() {
        return this.state.all_events.map((item, index) => {
            return <option key={item.id + index} value={item.id}> {item.name} </option>
        })
    }



    render() {
        let children = this.props.children

        const postDueAvailable = localStorage.getItem('postDueAvailable') === '1'

        let MainLayout =
            <div className="container-fluid">
                {
                    this.state.responsive
                    ? <Drawer
                        placement={'left'}
                        closable={false}
                        onClose={this.toggleCollapsed}
                        visible={this.state.collapsed}
                        key={'left'}
                    >
                        <Menu
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            selectedKeys={[this.state.test]}
                        >
                            <Menu.Item key="/admin/causes" onClick={ this.state.responsive ? ()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                <Link to="/admin/causes">
                                    <span>
                                        Mis Movimientos
                                    </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/profile">
                                <Link to="/admin/profile" onClick={ this.state.responsive ?()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                    <span>
                                        Mis Datos
                                    </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/pending">
                                <Link to="/admin/pending" onClick={ this.state.responsive ?()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                    <span>
                                        Mi Cuenta Corriente
                                    </span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/admin/agenda">
                                <Link to="/admin/agenda" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                    <span>
                                        Agendar Hora
                                    </span>
                                </Link>
                            </Menu.Item>
                            {/* <Menu.Item key="/admin/PdfShow">
                                <Link to="/admin/PdfShow" onClick={ this.state.responsive ?()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                    <span>
                                        Mi Pdf
                                    </span>
                                </Link>
                            </Menu.Item> */}
                            {this.state.responsive ? 
                                <Menu.Item key="5">
                                    <Link to="/" className="logout-btn"  style={{lineHeight: "46px"}} onClick={(e)=> { this.toggleCollapsed(); this.salir(e) } } > <img src={require("../../assets/images/logout.png")} /> <span>Salir</span> </Link>
                                </Menu.Item>
                            : null}
                        </Menu>
                    </Drawer>
                  : null
                }
                <Row>
                    <Col xl="12" >
                        <div className="nav-top">
                            <Navbar color="light" light expand="md">
                                <div className="logo-nav"><img src={require("../../assets/images/logo.png")} className="logo-admin" /></div>

                                <Nav className="ml-auto align-items-center" navbar>
                                    <h6 style={{margin: "0px 20px 0px 0px", lineHeight: "46px"}}>{this.state.username}</h6>
                                    <Collapse navbar>
                                        <Nav navbar className="navbar-right">
                                            <NavItem>
                                                <Link to="/" className="logout-btn" onClick={(e)=> this.salir(e) } > <img src={require("../../assets/images/logout.png")} /> <span>Salir</span> </Link>
                                            </NavItem>
                                        </Nav>
                                    </Collapse>
                                    {this.state.responsive ?  <Button type="white" className="toggle-mobile" onClick={this.toggleCollapsed}>
                                        <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                                    </Button> : null}
                                </Nav>
                            </Navbar>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        this.state.responsive === false
                        ? <Col xl="2" lg="2" md="2" sm="3" xs="12" style={{paddingLeft:"0px", paddingRight: "0px"}}>
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    breakpoint="lg"
                                    collapsedWidth="0"
                                    defaultOpenKeys={['sub1']}
                                    selectedKeys={[this.state.test]}
                                    mode="inline"
                                    inlineCollapsed={this.state.collapsed}>
                                    <div className="header-admin">
                                        
                                        {/*<Button type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16 }}>
                                                <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                                            </Button>*/}
                                    </div>
                                    <Menu.Item key="/admin/causes" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                        <Link to="/admin/causes">
                                            <span>
                                                Mis Movimientos
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/profile">
                                        <Link to="/admin/profile" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                            <span>
                                                Mis Datos
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/pending">
                                        <Link to="/admin/pending" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                            <span>
                                                Mi Cuenta Corriente
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/agenda">
                                        <Link to="/admin/agenda" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                            <span>
                                                Agendar Hora
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/admin/archivo">
                                        <Link to="/admin/archivo" onClick={ this.state.responsive ?()=> this.toggle() : (e)=> this.Clicked(e)} >
                                            <span>
                                                Mis Archivos
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                    {/*<Menu.Item key="/admin/PdfShow">
                                        <Link to="/admin/PdfShow" onClick={ this.state.responsive ?()=> this.toggleCollapsed() : (e)=> this.Clicked(e)} >
                                            <span>
                                                Mi Pdf
                                            </span>
                                        </Link>
                                        </Menu.Item>*/}
                                    {this.state.responsive ? 
                                        <Menu.Item key="5">
                                            <Link to="/" className="logout-btn"  style={{lineHeight: "46px"}} onClick={(e)=> this.salir(e) } > <img src={require("../../assets/images/logout.png")} /> <span>Salir</span> </Link>
                                        </Menu.Item>
                                    : null}
                                </Menu>
                            
                        </Col>
                        : null
                    }
                    
                    <Col xl="10" lg="10" md="10" sm="9" xs="12" style={{ paddingBottom: '6em' }}>
                        {children}
                        {this.state.redirect ? 
                        <Redirect to="/" /> : null}
                    </Col>
                </Row>
              
                <a href='https://api.whatsapp.com/send/?phone=56956079661&text=Hola&type=phone_number&app_absent=0' target='_blank' style={{position:'absolute', zIndex:999999, bottom:10, right:10}} type="button" className="position-absolute btn btn-link">
                    <img src="/DSxOAUB0raA.png" height ="60" width="60" />                
                </a>

            </div>
        return MainLayout
    }
}
