import React, { Component } from 'react';
import {Row, Container, Col, FormGroup, Input, Form} from 'reactstrap';
import { Link } from 'react-router-dom';
import {Icon,  notification } from 'antd';
import {requestHttp} from '../Services/request.service';
import Swal from 'sweetalert2';
import Loader from '../Loader';
var CryptoJS = require("crypto-js")

class Register extends Component{

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      rut:'',
      password:'',
      confirm: '',
      loader: false, 
      alertError:''
    };
  }
  componentDidMount(){
    this.setState({
        collapsed: false,
        rut: '',
        password:'',
        confirm: ''
    })
}

handleConfirmBlur(e){
    if(this.state.password !== this.state.confirm){
        this.setState({
            alertError: "Las contraseñas no son iguales"
        })
    }
    else{
        this.setState({
            alertError: ''
        })
    }
}

async handleChange(e){
    await this.setState({
        [e.name] : e.value
    })
}

handleAlert = (error) => {
    const swal = require('sweetalert2');
    var temp = []
    temp = error.split('\n').join('<br>')
    swal({
        title: "Se encontraron los siguientes errores.",
        html: temp,
        confirmButtonText: 'Aceptar',
    });   
}

formateaRut(rut) {
    var actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (var i = inicio.length - 1; i >= 0; i--) {
            var letra = inicio.charAt(i);
            rutPuntos = letra + rutPuntos;
            if (j % 3 == 0 && j <= inicio.length - 1) {
                rutPuntos = "." + rutPuntos;
            }
            j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
}

async handleChange(e){
    if(e.name === "rut"){
        let format = this.formateaRut(e.value)
        await this.setState({
            rut: format
        });
    }
    else{
        await this.setState({
            [e.name] : e.value
        })
    }
}


async validarRut( Objeto ){
    var tmpstr = "";
    var intlargo = Objeto
    if(intlargo  ===  undefined || intlargo === ""){
        return false
    }
    if (intlargo.length > 0 )
    {
        var crut = Objeto
        var largo = crut.length;
        if ( largo <2 )
        {
            return false;
        }
        for (var i=0; i <crut.length ; i++ )
        if ( crut.charAt(i) != ' ' && crut.charAt(i) != '.' && crut.charAt(i) != '-' )
        {
            tmpstr = tmpstr + crut.charAt(i);
        }
        var rut = tmpstr;
        crut=tmpstr;
        largo = crut.length;

        if ( largo> 2 )
            rut = crut.substring(0, largo - 1);
        else
            rut = crut.charAt(0);

        var dv = crut.charAt(largo-1);

        if ( rut == null || dv == null )
        return 0;

        var dvr = '0';
        var suma = 0;
        var mul  = 2;

        for (var i= rut.length-1 ; i>= 0; i--)
        {
            suma = suma + rut.charAt(i) * mul;
            if (mul == 7)
                mul = 2;
            else
                mul++;
        }
        var res = suma % 11;
        if (res==1)
            var dvr = 'k';
        else if (res==0)
            dvr = '0';
        else
        {
            var dvi = 11-res;
            dvr = dvi + "";
        }

        if ( dvr != dv.toLowerCase() )
        {
            return false;
        }
        return true;
    }
}

async handleSubmit(e) {
    e.preventDefault();
    this.setState({
        loader: true
    })
    let valido = true
    let error = "" 
    if(await this.validarRut(this.state.rut) === false ){
        valido = false;
        error += "El rut no es correcto. \n"
    }
    if(this.state.password === ""){
        valido = false;
        error += "Ingresa tu contraseña. \n"
    }
    if(this.state.password !== this.state.confirm){
        valido = false;
        error += "Las contraseñas son distintas. \n"
    }
    if( !valido ){
        var array = new Array()
        array = error.split("\n")
        array.splice( array.length-1)
        for(var i = 0; i < array.length; i++){
            this.setState({
                error: array,
                loader: false
            })
        }
        this.handleAlert(error)
        return false;
    }
    else {
        var ciphertext = CryptoJS.AES.encrypt(this.state.password, 'secret key 123');
        console.log("encrypted text", ciphertext.toString());
        let registro =  await requestHttp.login(this.state.rut, ciphertext.toString())
        if(registro){
            this.setState({
                loader: false
            })
            notification.open({
                message: 'Usuario Creado',
                description:
                    'Usuario creado exitosamente',
            });
            this.props.history.push("/")
        }
        else{
            notification.open({
                message: 'Ha ocurrido un error',
                description:
                    'Ha ocurrido al ingresar a la plataforma. Por favor, intentar más tarde',
            });
        }
    }
};


  render() {
        return(
            <main>
                {this.state.loader ? <Loader /> : null}
                <div className="login-layout">
                    <Container>
                        <Row>
                            <Col xl={{size: "4"}} lg="4"  md="12">
                                <FormGroup>
                                    <Link to="/"> <img src={require("../../assets/images/logo.png")} alt="logo" className="img-responsive"/></Link>
                                    <br/>
                                    <hr className="orange-hr"/>
                                </FormGroup>
                                <FormGroup>
                                    <br/>
                                    <h2>SUCURSAL VIRTUAL</h2>
                                    <br/>
                                </FormGroup>
                            </Col>
                            <Col xl={{size: "8"}} lg="8" md="12" >
                                <Row>
                                    <Col xl="12" className="text-left">
                                        <p>Si eres cliente del Grupo Defensa, pero nunca has ingresado a nuestra Sucursal Virtual, debes crear tu primera contraseña. Ingresa tu RUT y escribe la contraseña que desees. Recuerda colocar una contraseña segura y no compartirla con nadie.</p>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </Col>
                                    <Col xl={{size: "8", offset: "2"}} lg="8" >
                                        <Form onSubmit={(e) => this.handleSubmit(e)} className="login-form">
                                            <FormGroup>
                                                <Input placeholder="Rut" onChange={(e) =>this.handleChange(e.target)} type="text" name="rut" value={this.state.rut} required/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Input placeholder="Contraseña" onChange={(e) =>this.handleChange(e.target)} onBlur={(e) => this.handleConfirmBlur(e)} type="password" name="password" value={this.state.password} required/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Input placeholder="Confirmar contraseña" onChange={(e) =>this.handleChange(e.target)} type="password" name="confirm" value={this.state.confirm} onBlur={(e) => this.handleConfirmBlur(e)} required/>
                                                { this.state.alertError !== "" ? <small style={{color: "#dd0000"}}>{this.state.alertError}</small> : null }
                                            </FormGroup>
                                            <br/>
                                            <button type="submit" className="main-btn">
                                                <span>Crear</span>
                                            </button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            <br/>
            <br/>
            </main>
        )
    } 
}
export default Register;