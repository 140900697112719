import React, { Component } from 'react';
import {notification} from 'antd';
import {Row, Col, FormGroup, Input, Form} from 'reactstrap';
import {requestHttp} from '../Services/request.service';
import {Comunas} from "./partials/Comunas";
import {chileCities} from "./partials/Cities";
import Loader from '../Loader';
import { caseToZoho } from '../Services/client_data'
var CryptoJS = require("crypto-js")


class Profile extends Component{
    constructor(props) 
    {
        super(props)
        this.state = {
            isOpen: false,
            isLogged: false,
            loader: true,
            error: [],
            name: "",
            phone: "",
            comuna: "",
            lastName: "",
            city: "",
            email: "",
            password: "",
            comunas: Comunas,
            cities: chileCities,
            confirm: "",
            alertError: "",
            loader: true,
            usuario: {},
            old:{
                name: "",
                phone: "",
                comuna: "",
                lastName: "",
                city: "",
                email: "",
                rut: ''
            },
            rut: ""
        };
    }

    async componentDidMount(){

        let savedrut = localStorage.getItem("rutBuscado")
        //let withoutDot = savedrut.split(".").join("");
        let validateClient = null

        try {
            validateClient = JSON.parse(sessionStorage.getItem('rutBuscadovalidateClient'))
        }
        catch (err) {
            console.log(err)
            validateClient = null
        }

        if(validateClient){
            let user  = JSON.parse(sessionStorage.getItem('user'))

            validateClient = caseToZoho(validateClient)
            
            let name = ""
            let lastname = ""
            var temp = []
            var str = (validateClient.Full_Name||'').length > 0
                ? validateClient.Full_Name
                : `${validateClient.First_Name} ${validateClient.Last_Name}`
            
            temp = str.split(' ')
            if(temp.length === 3 ){
                name =  temp.slice(-1).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.includes("de") && temp.includes("la") && temp.length > 5 ){
                
                name =  temp.slice(-1).join(" ")
                lastname =  temp.slice(0, 4).join(" ")
            }
            else if(temp.includes("de") && temp.includes("la") ){
                
                name =  temp.slice(-2).join(" ")
                lastname =  temp.slice(0, 4).join(" ")
            }
            
            else if(temp.includes("de", 0) && temp.length === 5 || temp.includes("la", 1) && temp.length === 5){
                
                name =  temp.slice(-1).join(" ")
                lastname =  temp.slice(0, 4).join(" ")
            }
            else if(temp.includes("del") && temp.length >= 5){
                
                name =  temp.slice(-3).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.length === 5){
                
                name =  temp.slice(-3).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.length >= 6){
                
                name =  temp.slice(-4).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.length === 4 ){
                name =  temp.slice(-2).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            await this.setState({
                loader: false,
                name: lastname,
                lastName: name,
                phone: validateClient.Phone,
                comuna: validateClient.Ciudad,
                email: validateClient.Email,
                city: validateClient.Ciudad,
                usuario: user[0],
                address: validateClient.Domicilio,
                rut: savedrut,
                old:{
                    name: lastname,
                    phone: validateClient.Phone,
                    comuna: validateClient.Ciudad,
                    lastName: name,
                    city: validateClient.Ciudad,
                    email: validateClient.Email,
                    rut: savedrut,
                    date: new Date
                },
            })
        }
        else {
            await this.setState({
                loader: false,
                name: 'Busque cliente por rut ',
                lastName: '',
                phone: '',
                comuna: '',
                email: 'demo@mail.cl',
                city: '',
                usuario: '',
                address: '',
                rut: '',
                old:{
                    name: '',
                    phone: '',
                    comuna: '',
                    lastName: '',
                    city: '',
                    email: '',
                    rut: '',
                    date: new Date(),
                },
            })
        }
       
    }
    
    handleAlert = (error) => {
        const swal = require('sweetalert2');
        var temp = []
        temp = error.split('\n').join('<br>')
        swal({
            title: "Se encontraron los siguientes errores.",
            html: temp,
            confirmButtonText: 'Aceptar',
        });   
    }
    handleConfirmBlur(e){
        if(this.state.password !== this.state.confirm){
            this.setState({
                alertError: "Las contraseñas no son iguales"
            })
        }
        else{
            this.setState({
                alertError: ''
            })
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        let valido = true
        let error = "" 
        if(this.state.password === ""){
            valido = false;
            error += "Ingresa tu contraseña. \n"
        }
        if(this.state.password !== this.state.confirm){
            valido = false;
            error += "Las contraseñas no son iguales \n"
        }
        if( !valido ){
            var array = new Array()
            array = error.split("\n")
            array.splice( array.length-1)
            for(var i = 0; i < array.length; i++){
                this.setState({
                    error: array,
                })
            }
            this.handleAlert(error)
            return false;
        }
        else {
            var ciphertext = CryptoJS.AES.encrypt(this.state.password, 'secret key 123');
            console.log("encrypted text", ciphertext.toString());
            let params = {
                "clave": ciphertext.toString(),
            }
            console.log(params)
            var bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), 'secret key 123');
            var plaintext = bytes.toString(CryptoJS.enc.Utf8);
            console.log("decrypted text", plaintext);
            let registro =  await requestHttp.putLog(this.state.usuario.id, params)
            if(registro){
                this.setState({
                    loader: false
                })
                notification.open({
                    message: 'Contraseña actualizada',
                    description:
                        'La contraseña ha sido actualizada exitosamente',
                });
                this.props.history.push("/admin_plus/causes")
            }
            else{
                notification.open({
                    message: 'Ha ocurrido un error',
                    description:
                        'Ha ocurrido al ingresar a la plataforma. Por favor, intetar más tarde',
                });
            }
        }
    };
    async handleUpdate(e) {
        e.preventDefault();
        this.setState({
            loader: true
        })
        let valido = true;
        let error = "";
        var hasNumber = /\d/;
        if(this.state.name === ""){
            valido = false;
            error += "Ingresa tu nombre. \n"
        }
        if(this.state.lastName === ""){
            valido = false;
            error += "Ingresa tu apellido. \n"
        }
        if(this.state.email === ""){
            valido = false;
            error += "Ingresa tu correo. \n"
        }
        if(this.state.phone === ""){
            valido = false;
            error += "Ingresa tu teléfono. \n"
        }
        if(this.state.comuna === ""){
            valido = false;
            error += "Ingresa tu comuna. \n"
        }
        if(this.state.city === ""){
            valido = false;
            error += "Ingresa tu ciudad. \n"
        }
        if(this.state.address === ""){
            error += "Ingresa la dirección del evento \n"
            valido = false
        }
        else if(!hasNumber.test(this.state.address)){
            error += "La dirección debe contener un número \n"
            valido = false
        }
        if (!valido ) {
            var array = new Array()
            array = error.split("\n")
            array.splice( array.length-1)
            for(var i = 0; i < array.length; i++){
                await this.setState({
                    error: array,
                    loader: false
                })
            }
            this.handleAlert(error)
            return false;
        }
        else{
            let savedrut = this.state.rut.split(".").join("");
            let validateClient = JSON.parse(sessionStorage.getItem('rutBuscadovalidateClient'))
            
            if (validateClient ) {
                validateClient = caseToZoho(validateClient)

                let user  = JSON.parse(sessionStorage.getItem('user'))
                let name = ""
                let lastname = ""
                var temp = []
                var str = (validateClient.Full_Name||'').length > 0
                    ? validateClient.Full_Name
                    : `${validateClient.First_Name} ${validateClient.Last_Name}`
                
                temp = str.split(' ')
                if(temp.length === 3 ){
                    name =  temp.slice(-1).join(" ")
                    lastname =  temp.slice(0, 2).join(" ")
                }
                else if(temp.includes("de") && temp.includes("la") && temp.length > 5 ){
                    
                    name =  temp.slice(-1).join(" ")
                    lastname =  temp.slice(0, 4).join(" ")
                }
                else if(temp.includes("de") && temp.includes("la") ){
                    
                    name =  temp.slice(-2).join(" ")
                    lastname =  temp.slice(0, 4).join(" ")
                }
                
                else if(temp.includes("de", 0) && temp.length === 5 || temp.includes("la", 1) && temp.length === 5){
                    
                    name =  temp.slice(-1).join(" ")
                    lastname =  temp.slice(0, 4).join(" ")
                }
                else if(temp.includes("del") && temp.length >= 5){
                    
                    name =  temp.slice(-3).join(" ")
                    lastname =  temp.slice(0, 2).join(" ")
                }
                else if(temp.length === 5){
                    
                    name =  temp.slice(-3).join(" ")
                    lastname =  temp.slice(0, 2).join(" ")
                }
                else if(temp.length >= 6){
                    
                    name =  temp.slice(-4).join(" ")
                    lastname =  temp.slice(0, 2).join(" ")
                }
                else if(temp.length === 4 ){
                    name =  temp.slice(-2).join(" ")
                    lastname =  temp.slice(0, 2).join(" ")
                }
                let params = {
                    'id': validateClient.id,
                    'rut': savedrut,
                    'name': lastname,
                    'lastName': name,
                    'address': this.state.address,
                    'comuna': this.state.comuna,
                    'city': this.state.city,
                    'email': this.state.email,
                    'fono': this.state.phone
                }
                
                let updateClient = await requestHttp.updateClient(params)
                
                if(updateClient){
                    let json = this.state.usuario.logs
                    if( Array.isArray(json)){
                        json.push(this.state.old)
                    }
                    else{
                        json = [this.state.old]
                    }
                    let history = {
                        "logs": json
                    }
                    let logs = await requestHttp.putLog(this.state.usuario.id, history)
                    this.setState({
                        loader: false
                    })
                    notification.open({
                        message: 'Datos actualizados',
                        description:
                            'Importante: Las actualización de los datos  se verán reflejados dentro de los próximos minutos',
                    });

                    sessionStorage.setItem('rutBuscadovalidateClient', JSON.stringify(updateClient))
                    
                    this.props.history.push("/admin_plus/causes")
                }
                else{
                    this.setState({
                        loader: false
                    })
                    notification.open({
                        message: 'Ha ocurrido un error',
                        description:
                            'Ha ocurrido al actualizar los datos. Por favor, intetar más tarde',
                    });
                }
            }
        }
    };

    async handleChange(e){
        await this.setState({
            [e.name] : e.value
        })
    }

    renderComunas(){ 
        return this.state.comunas.map( (item, index)=>{
            return <option key={index} value={item.toLowerCase() == this.state.comuna ? this.state.comuna : item }>{item.toLowerCase() == this.state.comuna ? this.state.comuna.toLowerCase() : item} </option>
        })
    }
    renderCities(){ 
        return this.state.cities.map( (item, index)=>{
            return <option key={index} value={item.toLowerCase() == this.state.city ? this.state.city : item }>{item.toLowerCase() == this.state.city ? this.state.city.toLowerCase() : item }</option>
        })
    }

    render(){
        return(
                <div className="admin-container">
                    {this.state.loader ? <Loader /> : null}
                    <h4>Mis datos</h4>
                    <small>*Importante: Las actualización de los datos se verán reflejados dentro de los próximos minutos</small>
                    <hr/>
                    <Form onSubmit={(e) => this.handleUpdate(e)} className="profile-form">
                        <Row>
                            <Col xl="9" lg="11" md="12">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <FormGroup>
                                            <label>Rut</label>
                                            <Input name="rut" value={this.state.rut} disabled={true}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="6" lg="6">
                                        <FormGroup>
                                            <label>Nombre</label>
                                            <Input name="name" value={this.state.name} disabled/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" lg="6">
                                        <FormGroup>
                                            <label>Apellido</label>
                                            <Input name="lastName" value={this.state.lastName} disabled/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="6" lg="6">
                                        <FormGroup>
                                            <label>Correo</label>
                                            <Input onChange={(e) =>this.handleChange(e.target)} type="email" name="email" value={this.state.email} required/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" lg="6">
                                        <FormGroup>
                                            <label>Número de teléfono</label>
                                            <Input onChange={(e) =>this.handleChange(e.target)} name="phone" value={this.state.phone} required/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12" lg="12">
                                        <FormGroup>
                                            <label>Dirección</label>
                                            <Input onChange={(e) =>this.handleChange(e.target)} name="address" value={this.state.address} required/>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" lg="6">
                                        <FormGroup>
                                            <label>Comuna</label>
                                            <Input onChange={(e) =>this.handleChange(e.target)} type="select" name="comuna" value={this.state.comuna} required>
                                                { this.renderComunas()}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="6" lg="6">
                                        <FormGroup>
                                            <label>Ciudad</label>
                                            <Input onChange={(e) =>this.handleChange(e.target)} type="select" name="city" value={this.state.city} required>
                                                { this.renderCities()}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12">
                                        <button onClick={(e)=>this.handleUpdate(e) } type="submit" className="main-btn">
                                            <span>Guardar</span>
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                    <br/>
                    <br/>
                    <br/>
                    <h4>Contraseña</h4>
                    <p>Escribe tu nueva contraseña. Recuerda no compartir tus datos privados con nadie.</p>
                    <Form className="profile-form" onSubmit={(e)=> this.handleSubmit(e)}>
                        <Row>
                            <Col xl="4" lg="4">
                                <FormGroup>
                                    <label>Nueva Contraseña</label>
                                    <Input onChange={(e) =>this.handleChange(e.target)} type="password" name="password" value={this.state.password} required/>
                                </FormGroup>
                            </Col>
                            <Col xl="4" lg="4">
                                <FormGroup>
                                    <label>Confirmar Contraseña</label>
                                    <Input onChange={(e) =>this.handleChange(e.target)} type="password" name="confirm" value={this.state.confirm} onBlur={(e) => this.handleConfirmBlur(e)} required/>
                                    { this.state.alertError !== "" ? <small style={{color: "#dd0000"}}>{this.state.alertError}</small> : null }
                                </FormGroup>
                            </Col>
                            <Col xl="4" lg="4">
                                <FormGroup>
                                    <button onClick={(e)=>this.handleSubmit(e) } className="main-btn">
                                        <span>Actualizar</span>
                                    </button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
        )
    }
}
export default Profile;