import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect  } from "react-router-dom";
import MainLayout from './components/Layout/Index'
import MainLayoutPlus from './components/Layout_Plus/Index'
import WrappedLogin from './components/auth/Login'
import ForgetPassword from './components/auth/ForgetPassword';
import Causes from './components/admin/Causas';
import CausesPlus from './components/admin_plus/Causas';
import Profile from './components/auth/Profile';
import ProfilePlus from './components/auth/ProfilePlus';
import Register from './components/auth/ResetPassword';
import Pending from './components/admin/Pending';
import PendingPlus from './components/admin_plus/Pending';
import PdfShow from './components/admin/PdfShow';
import Agenda from './components/admin/Agenda';
import Archivo from './components/admin/Archivo';



// import PDFComponent from './components/admin/partials/PDFComponent'
// import Encript from './components/encipt';
// import PostDue from './components/admin/PostDue';

import 'antd/dist/antd.css'; 
import './assets/css/font.css';
import './assets/css/bootstrap.min.css';
import './assets/css/animate.css';
import './App.css'

/* aut*/
function App() {
  
  return (
      <Router>
        <Switch>
          <Route exact path="/" component={WrappedLogin} />
          <Route exact path="/login" component={WrappedLogin} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgetPassword} />
          <Route exact path={"/admin/PdfShow" } component={PdfShow} />

          {/*<Route exact path="/encript" component={Encript} />*/}
          

          {
            localStorage.getItem("Admin")!='si' ?
              <MainLayout>
                  <Route exact path={"/admin/causes" } component={Causes} />
                  <Route exact path={"/admin/profile" } component={Profile} />
                  <Route exact path={"/admin/pending" } component={Pending} />
                  <Route exact path={"/admin/agenda" } component={Agenda} />
                  <Route exact path={"/admin/archivo" } component={Archivo} />
                  {/*<Route exact path={"/admin/PdfShow" } component={PdfShow} />*/}
                  {/* <Route exact path={"/admin/postdue" } component={PostDue} /> */}
              </MainLayout>
            :
              <MainLayoutPlus>
                <Route exact path={"/admin/causes" } component={CausesPlus} />
                <Route exact path={"/admin/profile" } component={ProfilePlus} />
                <Route exact path={"/admin/pending" } component={PendingPlus} />
                {/* <Route exact path={"/admin/postdue" } component={PostDue} /> */}
              </MainLayoutPlus>
          }


        </Switch>
      </Router> 
    );
}

export default App;