import React, { Component } from 'react';

class Loader extends Component{

  render() {
        return(
           <div className="cont-loading">
                <div className="loading">
                </div>
           </div>
        )
    } 
}
export default Loader;