import {  API_FILES, API_URL } from '../../env'
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json';

class RequestHttp
{
    validateUser(email, password){
        return axios.post(API_URL/*'http://localhost:3001'*/ + '/getDataUser', { email, password })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }
    validateCustomer(rut) {
        return axios.post(API_URL + '/getDataClientByRut', { rut })
        .then(response => {
            return Promise.resolve(response.data[0]);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    customerHistory(rut) {
        return axios.post(API_URL + '/customerHistory', { rut })
        .then(response => {
            return Promise.resolve(response.data.result);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    login(rut, password) {
        return axios.post(API_URL + '/login', { rut, password })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    changePassword(id, password) {
        return axios.post(API_URL + '/changePassword', { id, password })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });   
    }

    putLog(id, data) {
        return axios.post(API_URL + '/putLog/' + id, data)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        }); 
    }
    
    updateClient(data) {
        return axios.post(API_URL + '/updateClient3', data)
        .then(response => {
            return Promise.resolve(response.data.result);
        })
        .catch(function(err) {
            return Promise.reject(err);
        }); 
    }

    getLoginData(rut) {
        return axios.post(API_URL + '/getLoginData', { rut })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        }); 
    }

    getUserData() {
        return axios.post(API_URL + '/getUserData', {})
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        }); 
    }

    loggedAgain(email, password) {
        return axios.post(API_URL + '/loggedAgain', { email, password })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        }); 
    }

    getDataClientByRut(rut, token) {
        return axios.post(API_URL + '/getDataClientByRut', { rut, token })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    getCases(id, token) {
        return axios.post(API_URL + '/getCases', { id, token })
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    getContract(rut, token) {
        return axios.post(API_URL + '/getContract', { rut, token })
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    sendEmail(email, password) {
        return axios.post(API_URL + '/sendEmail', { email, password })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    sendEmailWithAttachments(data) {
        return axios.post(API_URL + '/email/attachments', data)
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch((err) => {
            return Promise.reject(err.response ? new Error(err.response.body) : err)
        })
    }

    updateAutogestions(id, data) {
        return axios.post(API_URL + '/updateAutogestions/' + id, data)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    getDataByLogRegistro(rut, rol, proceso) {
        //return axios.post(API_URL + '/getContract', { rut, token })
        return axios.get('https://rocketbot.eastus.cloudapp.azure.com/api_sucursal_virtual/?rol='+rol+'&rut='+rut+'&nombre_proceso='+proceso)
        .then(response => {
            
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    getDataByMensajeFinal() {
        //return axios.post(API_URL + '/getContract', { rut, token })
        return axios.get('https://rocketbot.eastus.cloudapp.azure.com/api_sucursal_virtual/mensaje_final.php')
        .then(response => {
            
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    getCheckBaja(rut) {
        //return axios.post(API_URL + '/getContract', { rut, token })
        return axios.get('https://api_bajas.gdef.cl/?rut='+rut)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    saveVisited(rut) {
        //return axios.post(API_URL + '/getContract', { rut, token })
        return axios.get('https://api_bajas.gdef.cl/?visited='+rut)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }

    getMonthlyManagementPdf(email, rut, periodo) {
        //return axios.post(API_URL + '/getContract', { rut, token })
        return axios.get('https://api_bajas.gdef.cl/checkpdf2022.php?param='+email+'&periodo='+periodo+'&rut='+rut)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }
    getMonthlyManagementPdfRutAll(rut, periodo) {
        //return axios.post(API_URL + '/getContract', { rut, token })
        return axios.get('https://api_bajas.gdef.cl/checkpdf2022.php?periodo='+periodo+'&rut='+rut+'&option=fecha')
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        });
    }
   
    sendFile(data) {
        var formData = new FormData();
        formData.append("files",data.file);
        return axios.post(API_FILES, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'identifier': data.identifier,
                'fileType': data.fileType
            }
        })
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(function(err) {
            return Promise.reject(err);
        }); 
    }
}

export const requestHttp = new RequestHttp();
