import React, { Component } from 'react';
import {notification} from 'antd';
import {Row, Col, FormGroup, Input, Form} from 'reactstrap';
import {requestHttp} from '../../Services/request.service';
import Loader from '../../Loader';
import { caseToZoho } from '../../Services/client_data'
var CryptoJS = require("crypto-js")

class ChangePass extends Component{
    constructor(props) 
    {
        super(props)
        this.state = {
            isOpen: false,
            isLogged: false,
            loader: true,
            error: [],
            confirm: "",
            password: "",
            alertError: "",
            usuario: {},
            rut: "",
            email: ''
        };
    }

    async componentDidMount(){
        let savedrut = localStorage.getItem('token')
        let withoutDot = savedrut.split(".").join("");
        
        let user = sessionStorage.getItem('user')

        if (user) {
            user = JSON.parse(user)
        }

        if (!user.id) {
            user  = await requestHttp.getLoginData(savedrut)
        }
        
        if (user) {
            await this.setState({
                usuario: user[0]
            })

            sessionStorage.setItem('user', JSON.stringify(user))
        }

        let validateClient = sessionStorage.getItem('validateClient')

        if (validateClient) {
            validateClient = JSON.parse(validateClient)
        }
        else {
            validateClient = await requestHttp.validateCustomer(withoutDot)
            sessionStorage.setItem('validateClient', JSON.stringify(validateClient))
        }
        
        if (validateClient ) {
            validateClient = caseToZoho(validateClient)

            let name = ""
            let lastname = ""
            var temp = []
            var str = (validateClient.Full_Name||'').length > 0
                ? validateClient.Full_Name
                : `${validateClient.First_Name} ${validateClient.Last_Name}`
            
                temp = str.split(' ')
            if(temp.length === 3 ){
                name =  temp.slice(-1).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.includes("de") && temp.includes("la") && temp.length > 5 ){
                
                name =  temp.slice(-1).join(" ")
                lastname =  temp.slice(0, 4).join(" ")
            }
            else if(temp.includes("de") && temp.includes("la") ){
                
                name =  temp.slice(-2).join(" ")
                lastname =  temp.slice(0, 4).join(" ")
            }
            
            else if(temp.includes("de", 0) && temp.length === 5 || temp.includes("la", 1) && temp.length === 5){
                
                name =  temp.slice(-1).join(" ")
                lastname =  temp.slice(0, 4).join(" ")
            }
            else if(temp.includes("del") && temp.length >= 5){
                
                name =  temp.slice(-3).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.length === 5){
                
                name =  temp.slice(-3).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.length >= 6){
                
                name =  temp.slice(-4).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            else if(temp.length === 4 ){
                name =  temp.slice(-2).join(" ")
                lastname =  temp.slice(0, 2).join(" ")
            }
            
            await this.setState({
                loader: false,
                name: lastname,
                lastName: name,
                phone: validateClient.Phone ,
                comuna: validateClient.Ciudad,
                email: validateClient.Email,
                city: validateClient.Ciudad,
                address: validateClient.Domicilio,
                rut: validateClient.Rut,
                old:{
                    name: lastname,
                    phone: validateClient.Phone ,
                    comuna: validateClient.Ciudad,
                    lastName: name,
                    city: validateClient.Ciudad,
                    email: validateClient.Email,
                    rut: savedrut,
                    date: new Date,
                },
            })
        }
    }
    
    handleAlert = (error) => {
        const swal = require('sweetalert2');
        var temp = []
        temp = error.split('\n').join('<br>')
        swal({
            title: "Se encontraron los siguientes errores.",
            html: temp,
            confirmButtonText: 'Aceptar',
        });   
    }
    handleConfirmBlur(e){
        if(this.state.password !== this.state.confirm){
            this.setState({
                alertError: "Las contraseñas no son iguales"
            })
        }
        else{
            this.setState({
                alertError: ''
            })
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({
            loader: true
        })
        let valido = true
        let error = "" 
        var regExpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(this.state.password === ""){
            valido = false;
            error += "Ingresa tu contraseña. \n"
        }
        if(this.state.password !== this.state.confirm){
            valido = false;
            error += "Las contraseñas no son iguales \n"
        }
        if(this.state.email === ""){
            valido = false;
            error += "Ingresa un correo. \n"
        }
        else if (!regExpEmail.test(this.state.email)) {
            valido = false;
            error += 'El correo electrónico debe contener el formato correcto.\n';
        }
        if( !valido ){
            var array = new Array()
            array = error.split("\n")
            array.splice( array.length-1)
            for(var i = 0; i < array.length; i++){
                this.setState({
                    error: array,
                })
            }
            this.handleAlert(error)
            return false;
        }
        else {
            var ciphertext = CryptoJS.AES.encrypt(this.state.password, 'secret key 123');
            console.log("encrypted text", ciphertext.toString());
            let params = {
                "clave": ciphertext.toString(),
            }

            const registro = await requestHttp.updateAutogestions(this.state.usuario.id, params)
            
            if(registro){
                    
                let json = [this.state.old]
                let history = {
                    "logs": json
                }
                console.log(history)
                let logs = await requestHttp.updateAutogestions(this.state.usuario.id, history)
                localStorage.setItem('postDueAlertShow', '1')
                
                this.setState({
                    loader: false
                })

                notification.open({
                    message: 'Datos Actualizados',
                    description:
                        'Los datos han sido actualizados correctamente',
                });

                this.props.props.history.push("/admin/causes")
                window.location.reload()
            }
            else{
                this.setState({
                    loader: false
                })
                notification.open({
                    message: 'Ha ocurrido un error',
                    description:
                        'Ha ocurrido al intentar cambiar la contraseña. Intentalo más tarde',
                });
            }
        }
    };
    

    async handleChange(e){
        await this.setState({
            [e.name] : e.value
        })
    }

    render(){
        return(
                <div >
                    {this.state.loader ? <Loader /> : null}
                    <br/>
                    <div>
                        <img src={require("../../../assets/images/logo.png")} style={{maxWidth: "100%"}}/>
                        <hr className="orange-hr"/>
                    </div>
                    <h4>Contraseña</h4>
                    <p>Es tu primer ingreso a nuestra plataforma de Sucursal Virtual por lo que debes confirmar tu correo electrónico y cambiar tu contraseña antes de continuar. <br/>Escribe tu nueva contraseña. Recuerda no compartir tus datos privados con nadie.</p>
                    <Form className="profile-form" onSubmit={(e)=> this.handleSubmit(e)}>
                        <Row>
                            <Col xl="12" lg="12">
                                <FormGroup>
                                    <label>Correo electrónico</label>
                                    <Input onChange={(e) =>this.handleChange(e.target)} type="email" name="email" value={this.state.email} required/>
                                </FormGroup>
                            </Col>
                            <Col xl="12" lg="12">
                                <FormGroup>
                                    <label>Nueva Contraseña</label>
                                    <Input onChange={(e) =>this.handleChange(e.target)} type="password" name="password" value={this.state.password} required/>
                                </FormGroup>
                            </Col>
                            <Col xl="12" lg="12">
                                <FormGroup>
                                    <label>Confirmar Contraseña</label>
                                    <Input onChange={(e) =>this.handleChange(e.target)} type="password" name="confirm" value={this.state.confirm} onBlur={(e) => this.handleConfirmBlur(e)} required/>
                                    { this.state.alertError !== "" ? <small style={{color: "#dd0000"}}>{this.state.alertError}</small> : null }
                                </FormGroup>
                            </Col>
                            <Col xl="12" lg="12">
                                <FormGroup>
                                    <button onClick={(e)=>this.handleSubmit(e) } className="main-btn">
                                        <span>Actualizar</span>
                                    </button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
        )
    }
}
export default ChangePass;
